$(document).ready(function () {
  $('img.size-full, img.size-large').addClass('img-responsive');

  $('.article-body iframe').parent().addClass('embed-responsive embed-responsive-16by9');

  $('.article-body iframe').addClass('embed-responsive-item');

  $('.check-mailchimp').on('click', function (e) {
    e.preventDefault();

    var thisInput = $(this).parents('.container-checkbox').find('input[type=checkbox]');

    $(this).toggleClass('checked');

    if ($(this).hasClass('checked')) {
      thisInput.attr('checked', 'checked');
    } else {
      thisInput.removeAttr('checked');
    }
  });

  $('input[name=formContactEmail]').on('keyup', function () {
    var fieldVal = $(this).val();

    $(this).parents('.js-mailchimp-field').find('input[name=EMAIL]').val(fieldVal);
  });

  $('input[name=formContactEmail]').on('blur', function () {
    var fieldVal = $(this).val();

    $(this).parents('.js-mailchimp-field').find('input[name=EMAIL]').val(fieldVal);
  });

  $('.wpcf7-form input[type="file"]').on('change', function () {
    var defaultText = 'Nome do arquivo',
        filePath    = $('.wpcf7-form input[type="file"]').val(),
        fileTitle   = filePath.replace('C:\\fakepath\\', '');

    if (fileTitle === '') {
      $('.wpcf7-form .form-file-fake').text(defaultText);
    } else {
      $('.wpcf7-form .form-file-fake').text(fileTitle);
    }
  });
});

$(window).scroll(function () {
  var distanceFromTop = $(window).scrollTop();

  if (distanceFromTop > 0) {
    $('.navbar').addClass('scrolling');
  } else {
    $('.navbar').removeClass('scrolling');
  }
});
