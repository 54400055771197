$(document).ready(function() {


    // data corrente
    var data = new Date();
    var month = data.getMonth()+1;
    var day = data.getDate();
    var output = (day<10 ? '0' : '') + day + '/' + (month<10 ? '0' : '') + month + '/' + data.getFullYear();

    //primeiro e ultimo dia do mês corrente
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var day = firstDay.getDate();
    var outputFirstDay = (day<10 ? '0' : '') + day + '/' + (month<10 ? '0' : '') + month + '/' + data.getFullYear();

    var lastDay = new Date(y, m + 1, 0);
    var day = lastDay.getDate();
    var outputLastDay = (day<10 ? '0' : '') + day + '/' + (month<10 ? '0' : '') + month + '/' + data.getFullYear();

    $.post(urlajax, {action: 'listar_agenda', primeira_data:outputFirstDay, ultima_data:outputLastDay }, function(data) {
        var datelist = data;
        $("#datepicker").datepicker({
            dateFormat: 'dd/mm/yy',
          	dayNames: ['Domingo','Segunda','Terça','Quarta','Quinta','Sexta','Sábado'],
          	dayNamesMin: ['Dom','Seg','Ter','Qua','Qui','Sex','Sab','Dom'],
          	dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb','Dom'],
          	monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
          	monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun','Jul','Ago','Set','Out','Nov','Dez'],
          	nextText: 'Próximo',
          	prevText: 'Anterior',
            showOtherMonths: true,
            beforeShowDay: function(d) {
                // normalize the date for searching in array
                var dmy = "";
                dmy += ("00" + d.getDate()).slice(-2) + "-";
                dmy += ("00" + (d.getMonth() + 1)).slice(-2) + "-";
                dmy += d.getFullYear();
                if ($.inArray(dmy, datelist) >= 0) {
                    return [true, ""];
                }
                else {
                    return [false, ""];
                }
            },
            onChangeMonthYear: function(year, month, inst){

                $.post(urlajax, {action: 'agenda_mes', ano:year, mes:month }, function(data) {
                    $('.listaAgenda').html(' ');
                    if(data.length > 0){


                        data.forEach(function(item){
                            var dataId = item.data.split('/');
                            $('.listaAgenda').append(
                                                    '<li class="article" id="' + dataId[0] + dataId[1] + dataId[2] + '" >'+
                                                    '<h3>Data: ' + item.data + '</h3>' +
                                                    '<h3>Evento: ' + item.evento + '</h3>' +
                                                    '<h3>Local: ' + item.local + '</h3>' +
                                                    '<h3>Horário: ' + item.horario + '</h3>' +
                                                    '<h3>Descrição do evento: ' + item.descricao + '</h3>'
                                                );
                        });
                    }
                },'json');
            },
            onSelect: function (dateText, inst) {
               var data = dateText.split('/');
               console.log(data[0]+data[1]+data[2]);
               $('html, body').animate({
                scrollTop: $("#"+data[0]+data[1]+data[2]).offset().top-100
              }, 2000);
            }
        });
    },'json');


    $(document).on( "click",".js-topo" ,function() {
        $('html, body').animate({
            scrollTop: $("#datepicker").offset().top-100
          }, 1000);

      });
});
